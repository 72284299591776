<template>
  <div v-if="items.length > 0">
    <v-alert
      dense
      :prominent="!$vuetify.breakpoint.xs"
      class="ma-0 rounded-t-0 text-body-2"
      type="error"
      text
      v-if="settings.createNow && newItem"
    >
      Du solltest den Titel deiner Arbeit bis am
      <DateValue :value="settings.createEndDate" long /> erfassen
    </v-alert>
    <v-alert
      dense
      :prominent="!$vuetify.breakpoint.xs"
      class="ma-0 rounded-t-0 text-body-2"
      type="info"
      text
      v-else-if="settings.studentNow && settings.titleNow"
    >
      Du kannst den Titel deiner Arbeit bis am
      <DateValue :value="settings.titleEndDate" long /> anpassen
    </v-alert>
    <v-card flat class="rounded-t-0" :loading="loading">
      <v-list v-for="item in items" :key="'ma' + item.id" dense>
        <v-list-item
          :to="{ name: 'ThesisTitleEdit', params: { id: item.id } }"
          v-if="settings.titleNow || settings.createNow"
        >
          <v-list-item-content>
            <v-list-item-subtitle> meine Arbeit </v-list-item-subtitle>
            <v-list-item-title class="text-wrap">{{
              item.title ? item.title : "kein Titel erfasst"
            }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action><v-icon>mdi-pencil</v-icon></v-list-item-action>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-content>
            <v-list-item-subtitle> meine Arbeit </v-list-item-subtitle>
            <v-list-item-title class="text-wrap">{{
              item.title ? item.title : "kein Titel erfasst"
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <PersonItem
          v-if="item.firstCoach"
          :value="{ ...item.firstCoach, description: 'Betreuung' }"
        ></PersonItem>
        <PersonItem
          v-if="item.secondCoach"
          :value="{ ...item.secondCoach, description: 'Betreuung' }"
        />
        <PersonItem
          v-if="item.assessor"
          :value="{ ...item.assessor, description: 'Zweitbeurteilung' }"
        />
      </v-list>
    </v-card>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";
export default defineComponent({
  components: { DateValue, PersonItem },
  data() {
    return {
      loading: false,
      items: [],
      settings: {},
    };
  },
  computed: {
    newItem() {
      return this.items.filter((el) => el.id == 0).length > 0;
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.settings = await this.apiList({
        resource: "thesis/settings",
      });
      this.items = await this.apiList({
        resource: "thesis/thesis",
        query: "student=" + this.$_profilePerson.id,
      });

      this.loading = false;
      if (this.items.length > 0 && this.settings.studentNow) {
        this.$emit("loaded");
      }
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
